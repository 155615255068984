<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-emailtemplates-list')"
        @save="createItem"
        :title="currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">
        <b-card>
          <div class="mb-2">
            <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>
          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-2">
              <b-form-group :label="$t('Name')">
                <b-form-input v-model="currentItem.name" id="name" :placeholder="$t('Name')"/>
                <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{
                    $t(validation.name[0])
                  }}</small>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1 mt-2">
              <b-form-group :label="$t('identifier')">
                <b-form-input v-model="currentItem.identifier" id="identifier" :placeholder="$t('identifier')"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Subject')">
                <b-form-input v-model="currentItem.subject" id="subject" :placeholder="$t('Subject')"/>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Email Copy Receiver')">
                <b-form-input v-model="currentItem.copy_receiver" id="copy_receiver" :placeholder="$t('Email')"/>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Email Tag')">
                <b-form-select v-model="selected" :options="tempVariable" @change="textReplace">
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group :label="$t('Content')">
            <b-form-textarea ref="textarea" id="content" md="12" rows="10" :placeholder="$t('Content')"
                             v-model="currentItem.content"
            />
          </b-form-group>

          <b-form-group :label="$t('Content HTML')">
            <b-form-textarea ref="textarea_html" id="content" md="12" rows="10" :placeholder="$t('Content')"
                             v-model="currentItem.content_html"
            />
          </b-form-group>
        </b-card>
        <b-card>
          <b-button variant="success" type="submit">
            {{ $t('Add Emailtemplate') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormTimepicker,
  BFormSelect

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { required, alphaNum, email } from '@validations'
import moment from 'moment'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormCheckboxGroup,
    BFormFile,
    BAvatar,
    BMedia,
    quillEditor,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },

      currentItem: {
        id: '',
        status: 1,
        name: '',
        identifier: '',
        subject: '',
        content: '',
        content_html: '',
      },
      tempVariable: [],
      selected: null,
      loaded: true,
      validation: {},
      validate: false,
    }
  },

  methods: {
    createItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false

            if (success) {
              this.helperAddItem(`/emailtemplates`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-emailtemplates-edit',
                      params: {
                        id: response.data.id
                      },
                    },
                    () => setTimeout(() => {
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    textReplace(value) {

      const insertText = value
      if (!insertText.length) return

      const textarea = this.$refs.textarea.$refs.input

      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.currentItem.content = before + insertText + after

      this.$nextTick()
          .then(() => {
            textarea.selectionStart = pos + insertText.length
          })
    },

    getEmailVariables() {
      this.$http.get('email-variables')
          .then(response => {
            this.tempVariable = response.data.emailVariables
          })
    }
  },
  mounted() {
    this.getEmailVariables()
  },
}
</script>

<style>
.quill-editor .ql-container {
  height: 250px;
}
</style>
